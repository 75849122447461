<template>
  <b-card
    v-if="data"
    body-class="shadow"
  >
    <b-row>
      <b-col cols="12" class="p-0 m-0">
        <b-card-title class="position-absolute pl-1">
          {{disease}}
        </b-card-title>
        <vue-apex-charts
          height="130"
          ref="realtimeChart"
          :options="earningsChart.chartOptions"
          :series="data.series"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

const $earningsStrokeColors = ['#72a3d6', '#8c65e2', '#2772c0']
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: {},
    },
    vaccines: {
      type: Array,
      default: []
    },
    quantity: {
      type: Array,
      default:[]
    },
    disease: {
      type: String,
      default: ''
    }
  },
  watch: {
    vaccines(newValue){
      this.vacinas = newValue;
      this.updateOptions();
    },
    quantity(newValue){
      this.updateSeries(newValue);
    },
  },
  methods: {
     updateOptions() {
      this.$refs.realtimeChart.updateOptions({
        labels: this.vacinas,
      });
    },
    updateSeries(newValue) {
      this.$refs.realtimeChart.updateSeries(newValue);
    }
  },
  data() {
    return {
      vacinas: [],
      earningsChart: {
        chartOptions: {
          noData: {
          text: 'Nenhum estoque de doses para listar',
          },
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { 
            show: true,
            position: 'left',
            width: 220,
            height: 100,
            markers: {
                width: 5,
                height: 5
            },
          },
          comparedResult: [2, -3, 8],
          labels: [],
          stroke: { width: 0 },
          colors: $earningsStrokeColors,
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              expandOnClick: false,
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    offsetY: 15,
                    formatter: function () {
                      return 'Doses'
                    }
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Doses'
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1400,
              options: {
                chart: {
                  width: 180,
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1300,
              options: {
                chart: {
                  width: 350,
                  height: 120,
                },
                legend: {
                  show: false
                }
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  width: 300,
                  height: 120,
                }
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  width: 400,
                  height: 120,
                },
              },
            },
            {
              breakpoint: 800,
              options: {
                chart: {
                  width: 400,
                  height: 120,
                },
              },
            },
            {
              breakpoint: 500,
              options: {
                chart: {
                  width: 300,
                  height: 150,
                },
                legend: {
                  show: false
                }
              },
            },
            {
              breakpoint: 400,
              options: {
                chart: {
                  width: 250,
                  height: 100,
                },
                legend: {
                  show: false
                }
              },
            },
          ],
        },
      },
    }
  },
}
</script>
<style>
.shadow {
    box-shadow: 0px 0px 15px 5px #f2f3f3;
    border-radius: 0.5rem;
}
.apexcharts-legend.position-bottom.apexcharts-align-left, .apexcharts-legend.position-top.apexcharts-align-left, .apexcharts-legend.position-right, .apexcharts-legend.position-left {
    justify-content: end!important;
}
</style>
